import React, {PureComponent } from "react";
// import Contact from "../common/landing/contact";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainCarousel from "../common/CardCarousel/MainCarousel";
// import { Carousel } from "react-bootstrap";
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import SimpleSlider from "../common/SliderComponent/SimpleSlider";
import MailChimpForm from "./MailChimp";
import { Link} from 'react-router-dom';
import { useHistory } from "react-router-dom";

const dynamicCardsClass= "col-lg-4 col-md-3 mb-4 mb-md-0 col-wd-2 text-center";
 
class LandingPage extends PureComponent {

  

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      formData: {},
      sender_name: "",
      focus: false,
      demo:false
    };
    this.handleChange = this.handleChange.bind(this);
    AOS.init();

  }

  componentDidMount() {
    // AOS.init({
    //   duration: 800, // Duration of animation
    //   easing: 'ease-in-out', // Easing option
    //   // Whether animation should occur only once
    // });
  }
  
  componentWillUnmount() {
    AOS.refresh(); // Refresh AOS on component unmount
  }


  refreshPage() {
    window.location.reload(false);
  }


  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }
   
  
  render() {
    return (
      <>
        <div className="home-page-conatiner landing-page-main-class">
          <div className="landing-page-conatiner">
            <section className="section-1 green-bg-section">
              <div className="container lp-main-container-2   pt-80 pb-80">
                <div className="row">
                  <div className="col-lg-7 col-sm-7">

                    <div className="max-width  pt-80">
                      {/* <h3 className="Site-Heading mb-1 pe-6" data-aos="fade-up" data-aos-duration="1250">Dragnet Alpha</h3> */}
                    {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                    <h1 className="Early-Access-Release " data-aos="fade-up" data-aos-duration="1250">
                    Intelligent Online Content Monitoring Platform for Enterprises
                    </h1>
                    <p  className="font-fff section-1-title " data-aos="fade-up" data-aos-duration="1250">
                    An Intelligent News & Content monitoring application especially built for Enterprises to help make critical and informed business decisions.
                    </p>
                    {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                     <button
                      className="btn btn-primary sm-primary sm-primary-1 trialButton my-3 mr-3 black-border"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book a Discovery Call 
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free"
                      onClick={()=>{window.open(`https://sso.zigram.org/realms/ZIGRAM/protocol/openid-connect/registrations?client_id=DA&response_type=code`, "_blank")}}
                    >
                     Sign Up For Free 
                    </button>

                    </div>
                  </div>
                  <div className="col-lg-5 col-sm-5 img-div text-center d-flex align-tems-center">
                    {/* <img className="screenviewimg"
                      src={require("../../assets/Landing page icons/DAMainImage-removebg-preview.png")}
                    /> */}
                    <object data={require("../../assets/Landing page icons/Dragnet Alpha Vector 1.svg")}></object>
                    </div>
                </div>
              </div>
            </section>
{/* 

            <section className="section-2 ">
              <div className="container lp-main-container-2 pt-25 pb-25" data-aos="fade-up" data-aos-duration="1250">
                 <MainCarousel/>
              </div>
            </section> */}

            <section className="section-2 section-grey">
              <div className="container lp-main-container-2" data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-80">
                  <div className="col-md-7 col-sm-12"> <img className="local-img" src={require("../../assets/Landing page icons/Frame 897.png")}/></div> 
                  <div className="col-md-5 col-sm-12 text-left "> <div className="purpose mt-5">
                    <h1 className="Made-For-a-Purpose mb-3">
                      Benefits
                    </h1>
                    <p className="">
                    It enables the team to:
                    <ul className="list-items">
                      <li className="my-2">Consume massive & aggregated - information, news and content </li>
                      <li className="my-2"> Monitor organizations or focus areas, continuously </li>
                      <li className="my-2">Make critical business decisions </li>
                    </ul>
                    </p>
                  </div></div>
                 
                </div>
             
              </div>
            </section>
            <section className="section-2 purple-background text-justify">

              <div className="container lp-main-container-2" data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-80">
                <div className="col-md-5 col-sm-12 text-left "> <div className="purpose mt-5">
                    <h1 className="Made-For-a-Purpose">
                      Work across <br/> multiple Teams
                    </h1>
                    <p className="p-0">
                    Structured workflow built to enable dynamic and seamless research & reporting for the user and collaboration among team members.

                    </p>
                  </div></div>
                  <div className="col-md-7 col-sm-12"> <img className="local-img" src={require("../../assets/Landing page icons/Frame 896.png")}/></div> 
              
                 
                </div>
             
              </div>
            </section>
              {/* <section className="section-2 ">
                     <div className="container lp-main-container">
                         <SimpleSlider />
                     </div>
              </section> */}
            <section className="section-2 section-grey">
              <div className="container lp-main-container" data-aos="fade-up" data-aos-duration="1250">
                {/* <div className="row row-mt py-50">
                  <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <h1 className="Made-For-a-Purpose">Pricing</h1>
                  </div>
                </div> */}
                  <div className="container lp-main-container">
                <div className="row row-mt pt-80">
                  <div className="purpose w-100">
                    <h1 className="Made-For-a-Purpose text-center">
                    Decision Tools
                    </h1>
                   
                  </div>
                </div>
                <div className="row row-mt pt-80">
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/heatmap.png")}
                      />
                      <div className="purpose-heading  font-size-14">
                        Heat Map
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Showcase the magnitude of adverse events linked to an entity or an event 
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/summary.jpg")}
                      />
                      <div className="purpose-heading font-size-14">
                        Summary 
                      </div>
                      <div className="purpose-grey-text font-size-12">
                       Automated summaries of stories for quick research 
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/association map.png")}
                      />
                      <div className="purpose-heading  font-size-14">
                       Association Map
                      </div>
                      <div className="purpose-grey-text  font-size-12">
                     Link entities, stories, events etc. via a visual association map
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mt pt-80 pb-80">
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/dashboard.png")}
                      />
                      <div className="purpose-heading font-size-14">
                        Dashboards
                      </div>
                      <div className="purpose-grey-text font-size-12">
                       Visualization of trends , updates and stories for decision making 
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Alert.png")}
                      />
                      <div className="purpose-heading font-size-14">
                        Alerts 
                      </div>
                      <div className="purpose-grey-text  font-size-12">
                        Alerts &  escalations via email in the event of a critical update 
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/grouping.png")}
                      />
                      <div className="purpose-heading  font-size-14">
                        Grouping 
                      </div>
                      <div className="purpose-grey-text  font-size-12">
                       Grouping and link similar stories addressing the same event
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-80 pb-80 row row-mt">
              <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/criticality.png")}
                      />
                      <div className="purpose-heading  font-size-14">
                       Crticality
                      </div>
                      <div className="purpose-grey-text  font-size-12">
                      Tag the criticality of news article or event
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/extraction.png")}
                      />
                      <div className="purpose-heading  font-size-14">
                        Entity Extraction 
                      </div>
                      <div className="purpose-grey-text  font-size-12">
                    Extract the entity names, types from content and news articles 
                      </div>
                    </div>
                  </div>
              </div>
              </div>
                <div className="row">
                {/* <div className={dynamicCardsClass}>
             <div className="card  plan-card">
               <div className="">
                 <div className="mt-3">
                 <span className="card-text font-size-16 ">PROFESSIONAL</span>
                 </div>
                 <div className="plan-price-tag">
            
                 <span className="plan-free">14000 RS/Month</span>

                 </div>
                 <div className="card-body">
                   <div className="plan-card-body mt-20 mb-20">
                   </div>
                   <hr/>
                   <div className="plan-body-li">
                     <ul>
                       <li>Unlimited Projects</li>
                       <li>Unlimited Search Cases</li>
                     </ul>
                   </div>
                  
                 </div>
                 <button className="btn btn-primary  priceBtn"> Get Started </button>
                
               </div>
             </div>
           </div>
           <div className={dynamicCardsClass}>
             <div className="card  plan-card">
               <div className="">
                 <div className="mt-3">
                 <span className="card-text font-size-16 ">PROFESSIONAL</span>
                 </div>
                 <div className="plan-price-tag">
            
                 <span className="plan-free">14000 RS/Month</span>

                 </div>
                 <div className="card-body">
                   <div className="plan-card-body mt-20 mb-20">
                   </div>
                   <hr/>
                   <div className="plan-body-li">
                     <ul>
                       <li>Unlimited Projects</li>
                       <li>Unlimited Search Cases</li>
                     </ul>
                   </div>
                  
                 </div>
                 <button className="btn btn-primary  priceBtn"> Get Started </button>
                
               </div>
             </div>
           </div> */}
           </div>
              </div>
            </section>
          </div>
          <section className="section-2 text-justify"> 
         <div className="container lp-main-container-2" data-aos="fade-up" data-aos-duration="1250">
                <div className="row">
                  <div className="col-lg-6 col-sm-12   img-div text-center d-flex align-tems-center" >
                  <object data={require("../../assets/Landing page icons/Dragnet Alpha Animated.svg")}></object>
                  </div>
                  <div className="col-lg-6 col-sm-12  pt-50 ">
                    <div className="max-width px-3" >
                    {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                    <h1 className="fontrandom text-left" >
                       Still have question?<br/><span> Connect </span>with us
                    </h1>
                    <div className="my-3">
                      <div className="d-flex listGroup mb-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div className=" text-left">
                          <h5 className="listItem">Explore our Technology and Data Asset Solutions</h5>
                        </div>
                      </div>
                      <div className="d-flex listGroup my-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div  className=" text-left">
                          <h5 className="listItem">Get Pricing Information</h5>
                        </div>
                      </div>
                      <div className="d-flex listGroup mt-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div  className=" text-left">
                          <h5 className="listItem">Free Trial or Demo</h5>
                        </div>
                      </div>
                    </div>
                    {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                     <button
                      className="btn btn-primary sm-primary sm-primary-1 trialButton my-3 mr-4"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book a Discovery Call 
                    </button>
                    <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free my-3"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                      Book a Free Demo 
                    </button>
                    </div>
                    </div>
                </div>
              </div>
            </section>

          <section className="p-0">
              <div className="section-grey" data-aos="fade-up" data-aos-duration="2000" data-aos-once="true" data-aos-offset="300">
                <div className="lp-main-container-2 container">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h2 className="WeeklyNewsletter">Subscribe to our weekly newsletter</h2>
                 </div>
                 <div className="col-md-7 col-sm-12">
                   <MailChimpForm/>
                 </div>
                 </div>
              </div>
              </div>
            </section>
        </div>
      </>
    );
  }
}

export default LandingPage;
