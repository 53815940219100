import React from 'react';


export default function TermsAndConstions() {
  return (
    <div className="home-container root-conatiner  disclaimer-ps">

     {/* start page title section */}
      <div className="api-box">
        <section className="wow fadeIn bg-light-gray padding-50px-tb page-title-small top-space api-header purple-background section-head-ps">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-12 col-md-12 text-center ">
                        <h1 className="Sectionhead  alt-font py-3 mb-0">Terms of Service</h1>
                    </div>
                </div>
            </div>
        </section>
       </div>
      {/* end page title section */}

      {/* start post content section */}
      <div className="disc-box">

      <section class="wow fadeIn animated px-45">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-lg-12 text-intro">

                        <div class="x_content text-left" style={{padding:"20px"}}>
                            <p>This Agreement sets forth the terms and conditions that apply to your access and use of the Dragnet Alpha. The Dragnet Alpha is a structured set of data, text, media files, products, information about businesses, Individuals, Events and more and includes such Content displayed and/or otherwise contained on the Site. The platform provides access to the Data Asset through the website, its affiliates or related parties websites, mobile applications, API, data exports, browser plugins, reports and widgets. The Platform and Data Asset (together, the “Service”) is operated by Dragnet Alpha and its related third parties.</p>

                            <h4>Accepting the terms</h4>
                            <p>By contributing or posting any content on the Dragnet Alpha(including making updates to existing content on the Dragnet Alpha Data Asset or adding new content to the Dragnet Alpha Data Asset), You grant Dragnet Alpha and our assigns, agents, and licensees the non-exclusive, irrevocable, royalty free, perpetual, worldwide license, with the right to sublicense through multiple tiers of sub-licensees, to use, reproduce, modify, display, perform, and distribute Your Content in any medium and through any form of technology whether now in existence or which comes into existence in the future. You should not upload any content in which You do not have sufficient rights to grant this license to Dragnet Alpha       </p>
                            <p>Dragnet Alpha owns all rights, title, and interests in intellectual property rights in any contributions by its employees, any derivative works developed by Dragnet Alpha and the compilations and collective works in the Dragnet Alpha Data Asset, including those works incorporating Your Content (but not rights to Your Content by itself).</p>
                            <p><strong>Please read these Terms of Service carefully as they contain important information regarding Your legal rights, remedies and obligations. These Terms of Service establish the rights that Dragnet Alpha and others in the user community will have in and to any content that You contribute to the Dragnet Alpha Data Asset.</strong></p>
                            <h4>Use of the Site</h4>
                            <p>Dragnet Alpha grants you a personal, revocable, non-exclusive, non-transferable, limited license to access and use the Site and the Content conditioned upon your compliance with these Terms of Service.</p>
                            <p>You may not license, sublicense, transfer, sell, resell, publish, reproduce, and/or otherwise redistribute the Site, including the Content or any component thereof in any manner (including, but not limited to, via or as part of any Internet site). You may not<br/></p>
                                <ul><li> use the Content or any portion of the Site as part of any intranet or other internal network;</li>
                                <li>create archival or derivative works based on the Content or any portion thereof or </li>
                               <li> modify, reverse-engineer, disassemble, decompile or store the Content or any portion of the Site.</li>
                               </ul>
                            <p>You may not use the Site for any illegal purpose or in any manner inconsistent with these Terms of Service. Dragnet Alpha may discontinue or change the Site and the Content, or their availability to you, at any time, however Dragnet Alpha is not under any obligation to update the Content following publication on the Site. The terms under Terms of Use will survive any such discontinuation.</p>
                            <p>Your Contributions to Dragnet Alpha</p>
                            <p>If You create or modify any content on Dragnet Alpha, You agree to<br/>
                                <ul> <li>provide accurate, and current information;</li>
                                <li>maintain the security of Your password and identification if You have one;</li>
                                <li>be fully responsible for all use of Your account and for any actions that take place using Your account.</li>
                                </ul>
                                </p>

                                <p>By contributing or posting any content on the Service (including making updates to existing content on the Dragnet Alpha Dataset or adding new content to the same) (“Your Content”), You grant Dragnet Alpha and our assigns, agents, and licensees the non-exclusive, irrevocable, royalty free, perpetual, worldwide license, with the right to sublicense through multiple tiers of sub-licensees, to use, reproduce, modify, display, perform, and distribute Your Content in any medium and through any form of technology whether now in existence or which comes into existence in the future. You should not upload any content in which You do not have sufficient rights to grant this license to Dragnet Alpha.</p>
                            <p>Dragnet Alpha owns all rights, title, and interests in intellectual property rights in any contributions by its employees, any derivative works developed by Dragnet Alpha and the compilations and collective works in the Dragnet Alpha Dataset, including those works incorporating Your Content (but not rights to Your Content by itself).</p>
                            <p>The Dragnet Alpha Dataset benefits from content, including media files, submitted by anonymous users, registered users, partners, and the Dragnet Alpha staff. This information may be inaccurate and / or out-of-date. Dragnet Alpha assumes no responsibility regarding the accuracy of the information in the Service. Use of such information is at Your own risk. Dragnet Alpha shall not be responsible for any failure to remove, or delay in removing, harmful, inaccurate, unlawful, or otherwise objectionable content from the Service. Dragnet Alpha has no responsibility or liability for the deletion or failure to store or display any content that is contributed to the Dragnet Alpha Dataset. The Dragnet Alpha Dataset may be changed, updated, or deleted without notice for any reason at Dragnet Alpha’s sole discretion.</p>
                            <h4>Non-Commercial Use of the Dragnet Alpha Dataset</h4>
                            <p><strong><br /> </strong>If You display or distribute all or part of the Dragnet Alpha Dataset (hereafter also referred to as the “Content”;), You are required to provide attribution that is plainly visible to all users of the Content. Attribution gives everyone in the community the opportunity to correct errors and keep the Content up to date. Our recommended attribution guidelines, for usage are described below. Ultimately, You have the responsibility to ensure You are in full compliance with the license terms whenever You use or reuse the Content.</p>
                            <h4>Attribution</h4>
                            <p>Attribution must clearly state that the Content is sourced from the Dragnet Alpha Dataset and link to the webpage of the source material on the Site.     </p>
                            <ul style={{marginBottom:"0"}}>
                            <li>When You are using Content from a specific entity within the Dataset, Dragnet Alpha asks that You use a snippet in the following form:</li>
                            </ul>
                            <code style={{paddingLeft:"50px", color:"#000"}}>Source:
                            <p style={{paddingLeft:"50px"}}>NAME on Dragnet Alpha</p>
                            </code>


                            <ul style={{marginBottom:"0"}}>
                            <li>When You are referencing Content from multiple entities within the Dataset, Dragnet Alpha asks that You use a snippet in the following form:</li>
                            </ul>
                            <code style={{paddingLeft:"50px",color:"#000"}}>Source:
                            <p style={{paddingLeft:"50px"}}>Dragnet Alpha</p></code>
                            <p>If you have any questions concerning attribution, feel free to contact us by e-mailing support@dragnetalpha.com. You may also consult the Creative Commons Attribution-NonCommercial Legal Code.</p>
                            <h4>Accessing the Dragnet Alpha Platform</h4>
                            <p>Dragnet Alpha may provide a number of different ways for You to access portions of the Service. These may include, but are not necessarily limited to, copying Content directly from the Site, receiving an export data file or other form of data dump directly from Dragnet Alpha or by using the Dragnet Alpha API thereby enabling You to build applications on top of the Dragnet Alpha Platform. You agree to utilize the Dragnet Alpha Platform, including the Dragnet Alpha API, in accordance with these Terms of Service and as outlined in the further documentation provided by us on the Site. For purposes of these Terms of Service, receipt of Content from the Platform by any means constitutes use of the Site and Service. Because Dragnet Alpha wishes to protect the integrity of the Dragnet Alpha Dataset for the entire user community, Dragnet Alpha does not allow You to use crawlers, spiders or other scraping technology to electronically cull data from the Site and Service. Any collection of any Content using these methods is a violation of these Terms of Service. For clarification, authorized use of the Dragnet Alpha API is permitted. Dragnet Alpha will utilize commercially reasonable efforts to provide the Dragnet Alpha Platform on a 24/7 basis but it shall not be responsible for any disruption, regardless of length. Furthermore, Dragnet Alpha shall not be liable for losses or damages you may incur due to any errors or omissions in any Content or due to Your inability to access the Dragnet Alpha Dataset due to disruption of the Dragnet Alpha Platform. Dragnet Alpha reserves the right in its sole discretion (for any reason or for no reason) and at any time without notice to You to change, suspend or discontinue the availability of the Services. Dragnet Alpha hereby reserves the right to change the prices of any of its services without any intimation to the uses in advance..</p>
                            <p>Dragnet Alpha reserves its full right to decline registration of any person / entity for all or any of its services / features. It also reserves its right to discontinue any services or features of any service to any person or entity, without assigning any reason or fact whatsoever. In case of paid subscribers, Dragnet Alpha shall refund that proportion of Subscription Fee which is equal to unexpired portion of subscription period, subject to:</p>
                            <ul>
                            <li>The user or subscriber is not guilty of violation of any conditions of this Agreement</li>
                            <li>Any government having jurisidiction over Dragnet Alpha, or User or the situs of services has not forbidden making available the services to the User for any reason whatsoever </li>
                            <li>Dragnet Alpha no longer being allowed in law to continue such services</li>
                            </ul>
                            <p>If You choose to utilize the Dragnet Alpha API or other Dragnet Alpha Platform tools that provide ongoing access to the Dragnet Alpha Dataset in a manner enabling the building of applications on top of the Dragnet Alpha Platform, Dragnet Alpha reserves the right in its sole discretion (for any reason or for no reason) and at any time without notice to You to suspend Your access to the Dragnet Alpha Dataset in the future or terminate Your rights under these Terms of Service to access, use and/or display the Dragnet Alpha API and/or such other Dragnet Alpha Platform tools.</p>
                            <h4>Elements of the Service</h4>
                            <p>The graphical layout and schema of the Site, and all other elements of the Site, software applications, Content and the Service not described above are owned by Dragnet Alpha or its licensors, and may not be reproduced without permission.</p>
                            <h6>User Conduct</h6>
                            <p>You represent, warrant and agree that no contributions of any kind submitted to the Site for the Dragnet Alpha Dataset or otherwise posted, transmitted, or shared by You on or through the Site or Service will violate or infringe upon the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary rights; or contain libelous, defamatory or otherwise unlawful material.</p>
                            <p>In addition,You agree not to:</p>
                            <ul>
                            <li>harvest or collect email addresses or other contact information of other users from the Site or Service by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;</li>
                            <li>use the Site or Service in any unlawful manner or in any other manner that could damage, disable, overburden or impair the Site or Service;</li>
                            <li>upload, post, transmit, share, store or otherwise make available any content that we deem to be harmful, threatening, unlawful, defamatory, infringing, abusive, inflammatory, harassing, vulgar, obscene, fraudulent, invasive of privacy or publicity rights, hateful, or racially, ethnically or otherwise objectionable;</li>
                            <li>impersonate any person or entity, or falsely state or otherwise misrepresent Yourself, Your age or Your affiliation with any person or entity;</li>
                            <li>upload, post, transmit, share or otherwise make available any unsolicited or unauthorized advertising, solicitations, promotional materials, &ldquo;junk mail,&rdquo; &ldquo;spam,&rdquo; &ldquo;chain letters,&rdquo; &ldquo;pyramid schemes,&rdquo; or any other form of solicitation;</li>
                            <li>upload, post, transmit, share, store or otherwise make publicly available on the Site or Service any private information such as Social Security numbers and credit card numbers;</li>
                            <li>solicit personal information from anyone under 18 or solicit passwords or personally identifying information for commercial or unlawful purposes;</li>
                            <li>upload, post, transmit, share or otherwise make available any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
                            <li>intimidate or harass another;</li>
                            <li>upload, post, transmit, share, store or otherwise make available content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any local, state, national or international law;
use or attempt to use another’s account, service or system without authorization from Dragnet Alpha, or create a false identity on the Site or Service.
</li>
                            <li>upload, post, transmit, share, store or otherwise make available content that, in our sole judgment, is objectionable or which restricts or inhibits any other person from using or enjoying the Site, or which may expose Dragnet Alpha or its users to any harm or liability of any type.
post anything that exploits children or minors or that depicts cruelty to animals.
</li>
                            </ul>
                            <h6>Payments, Cancellations and Refunds</h6>
                            <p>All information, reports, content and access rights purchased on Dragnet Alpha are non-refundable and non-cancellable. Therefore, please look at the sample of reports and information provided before purchasing them. If you face any problem with any report or information purchased, please contact the Dragnet Alpha team at contactus@dragnetalpha.com. However, in the case of non-delivery of ordered items, we shall refund payments made for the non-delivered items.
                            We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction.
                                </p>
                            <h6>Copyright Infringement</h6>
                            <p>Dragnet Alpha has in place certain legally mandated procedures regarding allegations of copyright infringement (as well as other intellectual property rights) occurring on the Site. Dragnet Alpha has adopted a policy that provides for the immediate suspension and/or termination of any Site user who is found to have infringed on the rights of Dragnet Alpha or of a third party, or otherwise violated any intellectual property laws of Dragnet Alpha or a third party. The Dragnet Alpha policy is to investigate any allegations of infringements of third party rights brought to Dragnet Alpha’s attention. . <br /> <br /> 
If You have evidence, know, or have a good faith belief that Your rights or the rights of a third party have been violated and You want Dragnet Alpha to delete, edit, or disable the material in question, You must provide Dragnet Alpha with all of the following information: (a) a physical or electronic signature of a person authorized to act on behalf of the owner of the exclusive right that is allegedly infringed; (b) identification of the right claimed to have been infringed, or, if multiple rights are covered by a single notification, a representative list of the relevant parts of the Service; (c) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material; (d) information reasonably sufficient to permit Dragnet Alpha to contact You, such as an address, telephone number, and if available, an electronic mail address at which You may be contacted; (e) a statement that You have a good faith belief that use of the material in the manner complained of is not authorized by the owner of rights, its agent, or the law; and (f) a statement that the information in the notification is accurate, and under penalty of perjury, that You are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
</p>
                            <h6>Links to Third Party Sites</h6>
                            <p>The Site contains (or You may be sent through the Site) links to other web sites (“Third Party Sites”) as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software and other content or items belonging to or originating from third parties on such websites (“Third Party Site”). Dragnet Alpha does not control or monitor such Third Party Sites or Third Party Site Content, and Dragnet Alpha is not responsible for any Third Party Sites or Third Party Site Content accessed through the Site or Service. Inclusion of, linking to or permitting the use or installation of any Third Party Site or any Third Party Site Content does not imply approval or endorsement thereof by Dragnet Alpha. If You decide to leave the Site or Service and access the Third Party Sites, You do so at Your own risk and You should be aware that Site Terms of Service and other Dragnet Alpha policies no longer govern.</p>
                            <h4>Relationship of the Parties</h4>
                            <p>You and Dragnet Alpha are independent contractors, and nothing in this Agreement will create any partnership, joint venture, agency, franchise, sales relationship, or employment relationship between the parties. You will have no authority to make or accept any offers or representations on Dragnet Alpha’s behalf.</p>
                            <h4>Termination</h4>
                            <p>Dragnet Alpha may terminate Your account, delete Your Dragnet Alpha entry and any of Your Content and/or prohibit You from using or accessing the Site for any reason, or no reason, at any time in its sole discretion, with or without notice.</p>
                            <h4>Privacy Policy</h4>
                            <p>Dragnet Alpha respects Your privacy. A complete statement of Dragnet Alpha’s current privacy policy can be found at <span><a href="/privacy-policy">https://www.Dragnet Alpha/privacy-policy</a></span>. Dragnet Alpha privacy policy is expressly incorporated into this Agreement by this reference.</p>
                            <p style={{marginBottom:"5px"}}><strong>Limitation of Liability</strong></p>
                            <p>Dragnet Alpha liability to you is limited to the maximum extent permitted by law, in no event shall Dragnet Alpha or its directors, employees, affiliates or agents be liable to you for damages of any kind (including, but not limited to, special, incidental, or consequential damages, lost profits, or lost data, regardless of the foreseeability of those damages) arising out of or in connection with your use of the site or service or any other materials or services provided to you by Dragnet Alpha. this limitation shall apply regardless of whether the damages arise out of breach of contract, tort, or any other legal theory or form of action.</p>
                            <h4>Indemnity</h4>
                            <p>You agree to indemnify, defend, and hold harmless Dragnet Alpha, its affiliates, officers, directors, employees, consultants, agents, and representatives from any and all third party claims, losses, liability, damages, and/or costs (including reasonable attorney fees and costs) arising from Your access to or use of the Site or Service (including the Content), Your violation of these Terms of Service, or Your infringement, or infringement by any other user of Your account, of any intellectual property or other right of any third party or Dragnet Alpha. Dragnet Alpha will notify You promptly of any such claim, loss, liability, or demand, and will provide You with reasonable assistance, at Your expense, in defending any such claim, loss, liability, damage, or cost.</p>
                            <h4>Governing Law</h4>
                            <p>These Terms of Service shall be construed in accordance with and governed by the laws of India, without reference to their rules regarding conflicts of law. You hereby irrevocably consent to the exclusive jurisdiction of the courts in Delhi, India in all disputes arising out of or related to the use of the Site or Service.
                            
                            </p>
                            <h4>Severability; Waiver</h4>
                            <p>If, for whatever reason, a court of competent jurisdiction finds any term or condition in this Agreement to be unenforceable, all other terms and conditions will remain unaffected and in full force and effect. No waiver of any breach of any provision of this Agreement shall constitute a waiver of any prior, concurrent, or subsequent breach of the same or any other provisions hereof, and no waiver shall be effective unless made in writing and signed by an authorized representative of the waiving party.</p>
                            <h4>Change of Control</h4>
                            <p>In the event of a change of control of Dragnet Alpha or the sale of substantially all of Dragnet Alpha's assets, all rights of Dragnet Alpha hereunder shall be transferable, without notice to you.
</p>
                            <p>Contact us by Email : <a href="mailto:contactus@dragnetalpha.com">contactus@dragnetalpha.com </a></p>
                            <h4>Trademarks</h4>
                            <p>The Dragnet Alpha mark and other Dragnet Alpha logos are registered trademarks of Dragnet Alpha in India.</p>

                            <h4>Use of Communication Facilities</h4>
                            <p>When using System on the Website you should do so in accordance with the following rules. Failure to comply with these rules may result in your Account being suspended or closed:
                            
                            </p>
                            <ul>
                            <li>You must not use obscene or vulgar language;</li>
                            <li>You must not submit Content that is unlawful or otherwise objectionable. This includes, but is not limited to, Content that is abusive, threatening, harassing, defamatory, ageist, sexist or racist;</li>
                            <li>You must not submit Content that is intended to promote or incite violence;</li>
                            <li>It is advised that submissions are made using the English language as</li>
                            <li>We may be unable to respond to enquiries submitted in any other languages;</li>
                            <li>The means by which you identify yourself must not violate these Terms and Conditions or any applicable laws;</li>
                            <li>You must not impersonate other people, particularly employees and representatives of Dragnet Alpha or Our affiliates; and</li>
                            <li>You must not use Our System for unauthorised mass-communication such as &ldquo;spam&rdquo; or &ldquo;junk mail&rdquo;.</li>
                            <li>You acknowledge that we reserve the right to monitor any and all communications made to Us or using Our System.</li>
                            <li>You acknowledge that we may retain copies of any and all communications made to Us or using Our System.</li>
                            <li>You acknowledge that any information you send to Us through Our System may be modified by Us in any way and you hereby waive your moral right to be identified as the author of such information. Any restrictions you may wish to place upon Our use of such information must be communicated to Us in writing, in advance and We reserve the right to reject such terms and associated information.</li>
                            </ul>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </section>

      </div>
      {/* end blog content section */}
   </div>
  );
}
