import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
    const DAdefinition = () => {
        const botMessage = createChatBotMessage('Dragnet Alpha is an intelligent Online Content Monitoring SaaS Platform for Enterprises.');
    
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, botMessage],
        }));
      };
    
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
           DAdefinition 
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;