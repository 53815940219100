import {LandingInstance} from './axios-file';

import { TICKER,
  SEND_MAIL_BASE_URL,
  CRM_ENTRY_BASE_URL,
} from './api-endpoints';




// ------------------|SEND MAIL ENDPOINTS |------------------
export function sendMail(mailData) {
  return LandingInstance.post(SEND_MAIL_BASE_URL,mailData);
}

export function CRM_Entry(first_name, last_name, email, subject, mobile,message) {
  return LandingInstance
    .post(CRM_ENTRY_BASE_URL,null,{
      params:{
        first_name,
        last_name,
        email,
        subject,
        mobile,
        message
      }
    })
    .then(res => {
      return res;
    })
    .catch(err => {
      throw err;
    });
}