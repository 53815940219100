import React, { PureComponent } from "react";
import "../../../../assets/style.css"
import DAlogo  from "../../../../assets/Landing page icons/DALOGO.svg"
import WidgetMenu from "../../WidgetComponent/WidgetMenu";

class CommonHeader extends PureComponent {

  constructor(props) {

    super(props);
    this.state = {
      fullName: window.sessionStorage.getItem('name'),
      accessToken:"",
      userData:{}
    };

  }
  redirectToLogin=(e)=>{
    window.location.replace(`${process.env.REACT_APP_APP_REDIRECTION_URL}login`);
  }

  render() {

    return (
      <>
      <div className="formpage main-hb header-main">
         <header>
         <nav class="navbar navbar-expand-lg  fixed-top navbar-light bg-light header-ps">
                          
           <div className="container-fluid header-padding">
           <a class="navbar-brand custom" href="/"><img src={DAlogo}></img></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  
  <div class="collapse navbar-collapse justify-content-end" id="navbarNav">

    <ul class="navbar-nav navbar-left menulinks" >
      <li class="nav-item">
      <a class="nav-link" href="/">Home<span class="sr-only">(current)</span></a>
      </li>
    {/* { this.props.scrollContact? <li class="nav-item">
        <a class="nav-link" onClick={this.props.scrollContact}>Contact <span class="sr-only">(current)</span></a>
      </li>:""} */}
      {/* <li className="nav-item">
        <button className="btn btn-primary sm-primary mtm-3 login-desi" onClick={this.redirectToLogin}> Login</button>
      </li> */}
         <li class="nav-item">
        <button class=" btn btn-primary sm-primary  loginBTN" onClick={this.redirectToLogin}>Login</button>
      </li>
      <li className="nav-item">
       <button className="btn btn-primary sm-primary  sm-white btn-w-135" onClick={()=>this.props.openBot(true)}>Book a Free Demo</button>
      </li>
      <li className="nav-item m20px">
         {/* <a class="nav-link" href="/"><img src={hamburger}></img></a> */}
         <WidgetMenu />

      </li>
    </ul>
  
  </div>
  </div>
</nav>
         </header>
      </div>

      </>
    );
  }
}

export default CommonHeader;
