import React ,{useState}from 'react';
import { CRM_Entry,sendMail} from '../../../apis/home-api';
const CustomMessage = () => {
const [data,setData]=useState({first_name:"",last_name:"",recipient_email:"",message:"",subject:"",phone:""});
const [success, setSuccess] = useState(false);
const submitForm = (e) => {
    e.preventDefault();
    let formData = {};
    formData['name'] = data.last_name +" "+data.first_name;
    formData['title'] =data.subject;
    formData['message']=data.message;
    formData['Email']=data.recipient_email;

    sendMail(formData)
      .then((res) => {
        if(res.status===400){
        }
      if(res.status===200){
        setData({first_name:"", last_name:"",recipient_email:"",message:"",subject:"",phone:""});
      }
    })

 
    CRM_Entry(data.first_name,data.last_name,data.recipient_email,data.subject,data.phone,data.message)
    .then((res)=>{
      if(res.status===200){
        setData({ first_name:"", last_name:"",recipient_email:"",message:"",subject:"",phone:""});
        setSuccess(true);
      }
    })
}
const handleChange=(e)=>{
    setData({...data,[e.target.name]:e.target.value})
}
    return (
        <div className='chatbot-container'>
        <form onSubmit={submitForm}>
        <div className='text-center mb-4x'>
           <h3 className='ChatHeading'>Contact Our Team</h3>
        </div>
            <div className="form-group d-flex">
            <input type="text" className="form-control"
                    name="first_name"
                    id="support-form-name"
                    placeholder="First Name*"
                    required
                    value={data.first_name}
                    onChange={handleChange}
                />
                <input type="text" className="form-control ml-3"
                    name="last_name"
                    id="support-form-name"
                    placeholder="Last Name*"
                    required
                    value={data.last_name}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <input type="email" className="form-control"
                    name="recipient_email"
                    id="support-form-email"
                    placeholder="Official Email*"
                    required
                    value={data.recipient_email}
                    onChange={handleChange}
                />
            </div>
            <div className='form-group'>
                <select className="form-control" id="support-form-subject"
                    name="subject"
                    onChange={handleChange}
                    value={data.subject}
                    required>
                    <option value="">Select Subject</option>
                    <option value="Dragnet Alpha -Request Demo">Request For Demo</option>
                    <option value="Dragnet Alpha -Book a Discovery Call">Book a Discovery Call</option>
                </select>
            </div>
            <div className="form-group">
                <input type="number" className="form-control no-arrow"
                    name="phone"
                    id="support-phone"
                    placeholder="Phone"
                    value={data.phone}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <textarea type="text" className="form-control"
                    name="message"
                    id="support-form-message"
                    placeholder="Message"
                    // required
                    value={data.message}
                    onChange={handleChange}
                />
            </div>
            <span className='requiredMSG my-2'>Fields marked with an asterisk (*) are required</span>
            {success && <div class="alert-div my-3 p-3">
                Thank you for reaching out to us. We will get back to you shortly.
            </div>}
            <button type="submit" className="contact-btn submitbtn">Submit</button>
        </form>
    </div>
    );
};

export default CustomMessage;