import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import Routes from "./Routes";
import { getAndSetClicks } from "./utils";
import CookiesAccept from "./components/common/cookies";
import { SECURITY_MESSAGE } from "./constants/global";
//import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from "styled-components";
import ReactGA from "react-ga";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "./components/common/ChatBot/config";
import MessageParser from "./components/common/ChatBot/MessageParser";
import ActionProvider from "./components/common/ChatBot/ActionProvider";
import "./components/common/ChatBot/_chatbot.scss";
import {ReactComponent as Logo} from './assets/Landing page icons/Chaticon.svg';
import CommonHeader from "./components/common/landing/Header/common-header";
import CommonFooter from "./components/common/landing/Footer/common-footer";
import {RxCross1} from "react-icons/rx";

function handleContext(e) {
  e.preventDefault();
  return alert(SECURITY_MESSAGE);
}

function App() {
  window.sessionStorage.setItem("FlashMessagePopup", true);
  const [show, setShow] = useState(false);
  let chatbotRef = useRef();
  // useEffect(() => {
  //       let handler = (e) => {
  //     if (!chatbotRef.current.contains(e.target)) {
  //       setShow(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);
  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // });
  const openBot=(value)=>{
       setShow(value);
   }
  return (
    <div onClickCapture={getAndSetClicks}>
      <CommonHeader openBot={openBot}/>
      <Routes openBot={openBot}/>
      <div className="chatbox-container" ref={chatbotRef}>
        {show && (
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            disableScrollToBottom={true}
          />
        )}
        <button
          className="botbutton"
          onClick={() => {
            setShow(!show);
          }}
        >
          {show?<RxCross1 className='white' style={{ width: '30px', height: '30px' }}/>:<Logo className='white' style={{ width: '30px', height: '30px' }}/>}
        </button>
      </div>
      <CookiesAccept />
      <CommonFooter/>
    </div>
  );
}

export default withRouter(App);
