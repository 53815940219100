import React from 'react'
import ResponsiveCarousel from './Carousel'

const MainCarousel = () => {
  return (
    <div className="row">
    <div className="col-lg-4 col-md-6 col-sm-12 ">
      <div className="purpose text-left-imp ">
        <h1 className="Made-For-a-Purpose p-0 text-left-imp">One Platform. <br/> Many Solutions.</h1>
        {/* <p className="p-0 text-left-imp">
        Optimize the process of Targeted News & Content Search and research – Efficient, Standardized & Smarter

        </p> */}
      </div>
      </div>
    <div className="col-lg-8 col-md-6 col-sm-12">
    <ResponsiveCarousel />
    </div>
    </div>
  )
}

export default MainCarousel
